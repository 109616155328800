<template>
  <div id="app">
    <Top />
    <div class="top-seat-box"></div>
    <Home />
    <!-- 工具栏，返回顶部 -->
    <backTop v-if="showBackTop" />
  </div>
</template>

<script>
import backTop from '@/component/backTop.vue'
import Home from '/src/pages/index/index.vue'
import Top from '@/windows/topWindow.vue'


export default {
  name: 'App',
  data() {
    return {
      showBackTop: false,
    }
  },
  components: {
    Home,
    Top,
    backTop
  },
  mounted() {
    // 添加监听事件
    window.addEventListener("scroll", this.scrolling);
    // 手机端跳转到h5页面
		// if (navigator.userAgent.indexOf('Mobile') !== -1) {
		// 	window.location.href = 'https://h5.hy151.com/';
		// }
  },
  methods: {
    scrolling() {
      let current = document.documentElement.scrollTop || document.body.scrollTop;
      if (current > 200) {
        this.showBackTop = true;
      } else {
        this.showBackTop = false;
      }
      // console.log('滚动事件', current);
    },
  }

} 
</script>

<style lang="scss">
@import '@/re.scss';

#app {
  .top-seat-box {
    height: 13.9vw;
    // width: 68.6vw;
  }
}
</style>

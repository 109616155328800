import Vue from 'vue'
import App from './App.vue';




import Vant from 'vant';
import 'vant/lib/index.css';

import {
  VueJsonp
} from 'vue-jsonp'

import VueQr from "vue-qr";
Vue.component('VueQr', VueQr)

import VueAMap from "vue-amap";

// 初始化vue-amap
VueAMap.initAMapApiLoader({
  key: "KREBZ-UUK6I-W3YGN-5SK4C-JK5H6-WGBCQ", // 这里写你申请的高德地图的key可以去https://console.amap.com/申请key
  plugin: ["AMap.Autocomplete", "AMap.Geocoder", "AMap.Geolocation"],
  v: "1.4.4",
  uiVersion: "1.1"
});

Vue.use(VueAMap);
Vue.use(VueJsonp)
Vue.use(Vant);
Vue.use(ElementUI);

import '@/cover.scss';

// import store from '@/store'
// const vuexStore = require('@/store/$u.mixin.js') // 引入uView提供的对vuex的简写法文件
// Vue.mixin(vuexStore)

// http拦截器，将此部分放在new Vue()和app.$mount()之间，才能App.vue中正常使用，参考：http://www.uviewui.com/js/http.html
// import requestConfig from '@/utils/request.config.js'

const app = new Vue({
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  },
  ...App
})

import $u from '@/utils/$u'
import $ui from '@/utils/ui'
import vtool from '@/utils/index'
import uni from '@/utils/uni'


Vue.prototype.$u = $u
Vue.prototype.$ui = $ui
Vue.prototype.uni = uni
Vue.prototype.$dy_tool = vtool

// 这里需要写在最后，是为了等Vue创建对象完成，引入"app"对象(也即页面的"this"实例)
// Vue.use(requestConfig, app)

Vue.mixin({
  data() {
    let vuex_user = null
    let vuex_token = null
    let member_token = null
    try {
      vuex_user = JSON.parse(localStorage.getItem('vuex_user'))
      vuex_token = localStorage.getItem('vuex_token')
      member_token = localStorage.getItem('member_token')
    } catch (error) {
      vuex_user = null
      vuex_token = null
      member_token = null
    }
    return {
      vuex_token: vuex_token,
      member_token: member_token,
      vuex_user: vuex_user,
    }
  },
})
// http接口API集中管理引入部分
import httpApi from '@/api/http.api.js'
Vue.use(httpApi, app)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

app.$mount('#app')